<template>
  <div class="min-h-dvh lg:grid lg:grid-cols-2 lg:items-center">
    <figure class="contents">
      <img
        src="~/assets/images/jaarbeurs-banner.png"
        alt="Jaarbeurs banner"
        class="hidden h-full object-cover lg:block"
      >
      <UiLogo class="hidden lg:absolute lg:bottom-10 lg:left-10 lg:inline-block" />
    </figure>
    <slot />
  </div>
</template>
